@import "./breakpoints";

// Small devices
@mixin sm {
  @media (min-width: #{$screen-sm-min}) {
    @content;
  }
}

// Medium devices
@mixin md {
  @media (min-width: #{$screen-md-min}) {
    @content;
  }
}

// Large devices
@mixin lg {
  @media (min-width: #{$screen-lg-min}) {
    @content;
  }
}

// Extra large devices
@mixin xl {
  @media (min-width: #{$screen-xl-min}) {
    @content;
  }
}

@function breakpoint($name) {
  @return map-get($breakpoints, $name);
}

@mixin minWidth($breakpoint) {
  @media (min-width: breakpoint($breakpoint)) {
    @content;
  }
}

@mixin maxWidth($breakpoint) {
  @media (max-width: breakpoint($breakpoint) - 1px) {
    @content;
  }
}
