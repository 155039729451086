@import "./mixins";

body {
  font-family: "Helvetica", "Arial", sans-serif;
  background: lightgrey;
}

////////// GLOBAL HEADER //////////

header {
  padding-top: 5rem;
  line-height: 0.8;

  a {
    &:visited {
      color: black;
    }
  }
}

@-moz-document url-prefix() {
  .title {
    line-height: 1;
  }
}

.title {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;

  @include maxWidth(tablet) {
    line-height: 0.7;

    @-moz-document url-prefix() {
      line-height: 1;
    }
  }
}

#nav-bear {
  position: absolute;
  min-width: 170px;
  min-height: 170px;
  max-width: 170px;
  max-height: 170px;
  top: 67px;

  @include maxWidth(mobile) {
    left: -20px;
    min-width: unset;
    max-width: unset;
    min-height: unset;
    max-height: unset;
    height: 80px;
    width: 80px;
    top: 10px;
  }
}

.header-bear img {
  max-width: 100%;
  &:hover {
    opacity: 0.5;
  }
}

.title span:first-of-type {
  font-style: italic;
  font-size: 4.3em;
}

.title span:nth-of-type(2) {
  text-transform: none;
  font-size: 1.5em;
}

.title span:last-of-type {
  display: inline-block;
  -webkit-transform: skewx(8deg);
  -o-transform: skewx(8deg);
  transform: skewx(8deg);
  font-weight: 500;
  font-size: 4em;
}

.container {
  width: 100%;
  display: flex;
  margin-top: 3em;

  &.left {
    position: relative;
    z-index: 0;
  }
}

.container img {
  min-width: 500px;
  max-width: 45%;
  margin-left: 5rem;
  position: absolute;

  @include maxWidth(mobile) {
    right: 13px;
    margin-left: 0;
    max-width: 95%;
    min-width: unset;
  }
}

#blue-1,
#blue-2,
#blue-3,
#blue-4,
#blue-5 {
  opacity: 0;
}

.container a {
  text-decoration: none;
  color: black;
  font-size: 29px;
}

.container a:hover {
  color: blue;
}

.left {
  width: 65%;

  @include maxWidth(mobile) {
    width: 100%;
  }
}

.right {
  margin-top: 6em;
  padding-left: 4em;

  h1 {
    display: none;
    color: #0000ee;
  }

  @include maxWidth(tablet) {
    display: none;
  }
}

img.map,
map area {
  outline: none;
}

/////////

////////// GLOBAL CONTAINERS //////////

main {
  margin-top: 5rem;

  &.index {
    margin-top: 0;
  }

  @include maxWidth(tablet) {
    margin-top: 3rem;
  }
}

.text-container {
  width: 70%;
  margin: 0 auto;

  @include maxWidth(tablet) {
    width: 90%;
  }

  a.back-link {
    display: inline-block;
    margin-bottom: 2rem;
  }

  .comic-image {
    max-width: 100%;

    &:first-of-type {
      margin-top: 2rem;
    }

    &:last-of-type {
      margin-bottom: 3rem;
    }
  }
}

.item-list {
  padding-top: 30px;

  @include maxWidth(tablet) {
    padding-top: 10px;
  }

  &.comics {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    @include maxWidth(tablet) {
      flex-direction: column;
    }

    .right {
      margin-top: 0;
      padding-left: 0;
    }
  }

  .item {
    margin-bottom: 1rem;

    &.comics {
      display: flex;

      .description {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-left: 2rem;
      }
    }
  }

  .music {
    p {
      margin-top: 0;
      padding-left: 3rem;

      @include maxWidth(tablet) {
        padding-left: 1rem;
      }
    }
    display: flex;
  }

  &#software {
    @include minWidth(desktop-small) {
      width: 75%;
    }
  }
}

//////////

////////// MUSIC //////////

.album-cover {
  width: 200px;
  height: 200px;
  &:hover {
    opacity: 0.7;
  }

  @include maxWidth(tablet) {
    width: 100px;
    height: 100px;
  }
}

///////// COMICS //////////

.thumbnail {
  width: 90px;
  height: 90px;

  &:hover {
    opacity: 0.5;
  }
}

////////// ABOUT //////////

.about-container {
  display: flex;
  //justify-content: space-between;
  p {
    width: 60%;
  }
}

.about-mask {
  width: 62.31px;
  height: 211.683px;
  margin-left: 3rem;
}

////////// SOFTWARE //////////

#donation {
  font-size: 14px;
}

.ag-images {
  margin-top: 2.5rem;
  img {
    width: 32%;
    min-width: 224px;
  }
}

.audio-examples {
  display: flex;
  flex-direction: column;
}

////////// CONTACT //////////

#contact-header {
  margin-bottom: 3rem;
}

#contact-image {
  position: relative;
  bottom: 220px;
  left: 385px;

  @include maxWidth(tablet) {
    position: unset;
  }
}
