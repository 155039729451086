body {
  font-family: "Helvetica", "Arial", sans-serif;
  background: lightgrey;
}

header {
  padding-top: 5rem;
  line-height: 0.8;
}
header a:visited {
  color: black;
}

@-moz-document url-prefix() {
  .title {
    line-height: 1;
  }
}
.title {
  text-transform: uppercase;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .title {
    line-height: 0.7;
  }
  @-moz-document url-prefix() {
    .title {
      line-height: 1;
    }
  }
}

#nav-bear {
  position: absolute;
  min-width: 170px;
  min-height: 170px;
  max-width: 170px;
  max-height: 170px;
  top: 67px;
}
@media (max-width: 575px) {
  #nav-bear {
    left: -20px;
    min-width: unset;
    max-width: unset;
    min-height: unset;
    max-height: unset;
    height: 80px;
    width: 80px;
    top: 10px;
  }
}

.header-bear img {
  max-width: 100%;
}
.header-bear img:hover {
  opacity: 0.5;
}

.title span:first-of-type {
  font-style: italic;
  font-size: 4.3em;
}

.title span:nth-of-type(2) {
  text-transform: none;
  font-size: 1.5em;
}

.title span:last-of-type {
  display: inline-block;
  -webkit-transform: skewx(8deg);
  -o-transform: skewx(8deg);
  transform: skewx(8deg);
  font-weight: 500;
  font-size: 4em;
}

.container {
  width: 100%;
  display: flex;
  margin-top: 3em;
}
.container.left {
  position: relative;
  z-index: 0;
}

.container img {
  min-width: 500px;
  max-width: 45%;
  margin-left: 5rem;
  position: absolute;
}
@media (max-width: 575px) {
  .container img {
    right: 13px;
    margin-left: 0;
    max-width: 95%;
    min-width: unset;
  }
}

#blue-1,
#blue-2,
#blue-3,
#blue-4,
#blue-5 {
  opacity: 0;
}

.container a {
  text-decoration: none;
  color: black;
  font-size: 29px;
}

.container a:hover {
  color: blue;
}

.left {
  width: 65%;
}
@media (max-width: 575px) {
  .left {
    width: 100%;
  }
}

.right {
  margin-top: 6em;
  padding-left: 4em;
}
.right h1 {
  display: none;
  color: #0000ee;
}
@media (max-width: 767px) {
  .right {
    display: none;
  }
}

img.map,
map area {
  outline: none;
}

main {
  margin-top: 5rem;
}
main.index {
  margin-top: 0;
}
@media (max-width: 767px) {
  main {
    margin-top: 3rem;
  }
}

.text-container {
  width: 70%;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .text-container {
    width: 90%;
  }
}
.text-container a.back-link {
  display: inline-block;
  margin-bottom: 2rem;
}
.text-container .comic-image {
  max-width: 100%;
}
.text-container .comic-image:first-of-type {
  margin-top: 2rem;
}
.text-container .comic-image:last-of-type {
  margin-bottom: 3rem;
}

.item-list {
  padding-top: 30px;
}
@media (max-width: 767px) {
  .item-list {
    padding-top: 10px;
  }
}
.item-list.comics {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
@media (max-width: 767px) {
  .item-list.comics {
    flex-direction: column;
  }
}
.item-list.comics .right {
  margin-top: 0;
  padding-left: 0;
}
.item-list .item {
  margin-bottom: 1rem;
}
.item-list .item.comics {
  display: flex;
}
.item-list .item.comics .description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-left: 2rem;
}
.item-list .music {
  display: flex;
}
.item-list .music p {
  margin-top: 0;
  padding-left: 3rem;
}
@media (max-width: 767px) {
  .item-list .music p {
    padding-left: 1rem;
  }
}
@media (min-width: 992px) {
  .item-list#software {
    width: 75%;
  }
}

.album-cover {
  width: 200px;
  height: 200px;
}
.album-cover:hover {
  opacity: 0.7;
}
@media (max-width: 767px) {
  .album-cover {
    width: 100px;
    height: 100px;
  }
}

.thumbnail {
  width: 90px;
  height: 90px;
}
.thumbnail:hover {
  opacity: 0.5;
}

.about-container {
  display: flex;
}
.about-container p {
  width: 60%;
}

.about-mask {
  width: 62.31px;
  height: 211.683px;
  margin-left: 3rem;
}

#donation {
  font-size: 14px;
}

.ag-images {
  margin-top: 2.5rem;
}
.ag-images img {
  width: 32%;
  min-width: 224px;
}

.audio-examples {
  display: flex;
  flex-direction: column;
}

#contact-header {
  margin-bottom: 3rem;
}

#contact-image {
  position: relative;
  bottom: 220px;
  left: 385px;
}
@media (max-width: 767px) {
  #contact-image {
    position: unset;
  }
}